import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  address: yup.string().required("Address is Required * "),
  city: yup.string().required("City is Required * "),
  state: yup.string().required("State is Required * "),
  zipcode: yup
    .number()
    .required("Zip Postal Code is Required * ")
    .positive("Zip Postal should be positive or non-zero")
    .typeError("Zip Postal should not be Empty"),
  country: yup.string().required("Country is Required *"),
});

const AddAddressDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let emailAddress = new URLSearchParams(location.search).get("email");

  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [countryFlag, setCountryFlag] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(false);
  const [userType, setUserType] = useState("");
  const [flag, setFlag] = useState();
  const [config, setConfig] = useState({}); // State to store config data

  useEffect(() => {
    const fetchDataFromStorage = async () => {
      try {
        const storedData = localStorage.getItem("userData");
        setFlag(localStorage.getItem("OauthFlag"));
        if (storedData) {
          const userData = JSON.parse(storedData);
          const userTypeFromStorage = userData.userType;
          setUserType(userTypeFromStorage);
        }
      } catch (error) {
        console.error("Error fetching and setting userType:", error);
      }
    };

    fetchDataFromStorage();
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmitVerify = (userId) => {
    const values = {
      userId: userId,
    };
    navigate("/success");
  };

  const onSubmitHandler = async (data, event) => {
    event.preventDefault();

    if (emailAddress == null) {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const userData = JSON.parse(storedData);
        emailAddress = userData.email;
        console.log(emailAddress);
      }
    }

    try {
      const response = await fetch(`${TQ_URL}/saveAddressDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailAddress,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          postalCode: data.zipcode,
          userType: data.organization,
          partnerId: config.partnerId, // Include partnerId from config.json
        }),
      });

      localStorage.setItem("redirectFrom", "addressDetails");

      const res = await response.json();

      if (response.status === 200) {
        userCreated(res.userId, res.message);
        localStorage.setItem(
          "userAddressDetails",
          JSON.stringify({ addressDetails: res.addressDetails })
        );
        handleSubmitVerify(res.userId);
      } else if (
        response.status === 400 &&
        res.message &&
        res.message.length > 0
      ) {
        let errorMsg = "";
        res.message.forEach((msg) => {
          errorMsg += ` ${msg}`;
        });
        toast.error(errorMsg, { position: "top-right" });
      } else {
        userNotCreated();
      }
    } catch (error) {
      console.error("Error during registration:", error);

      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        console.error("Error saving details:", error.message);
        userNotCreated();
      }
    }
  };

  const userCreated = (userId, msg) => {
    toast.success(msg, { position: "top-right" });
  };

  const userNotCreated = () => {
    toast.error("Failed to save the details", { position: "top-right" });
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    if (!zipcode) {
      setNotFoundMessage("Zip Postal Code is required");
      setStateName("");
      setCityName("");
      setCountryFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ zipcode: zipcode }),
        });

        if (response.status === 200) {
          const res = await response.json();
          if (res.Status === "Succsess") {
            setStateName(res.State.state);
            setCityName(res.State.city);
            setValue("state", res.State.state);
            setValue("city", res.State.city);
            setValue("country", "United States");
            setNotFoundMessage("");
            setCountryFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setCountryFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setCountryFlag(false);
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
      setCityName("");
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-profile-bg">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-28">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">
            Add Address
            <br />
            Details
          </h3>
        </div>
      </div>

      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl">
          <div className="text-center md:text-left mb-10 sm:mb-12">
            <Link to="/">
              <img
                src="assets/images/main-logo.svg"
                alt="Logo"
                className="h-10 mx-auto sm:mx-0"
              />
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {userType === "Individual" && flag === 1 ? (
              <>
                <div className="flex justify-between mb-5 w-1/2">
                  <div className="flex items-center">
                    <Controller
                      name="organization"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <div className="flex">
                          <input
                            {...field}
                            type="radio"
                            id="organization-radio"
                            value="Organization"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.organization && "border-red-500"
                            }`}
                          />
                          <label
                            htmlFor="organization-radio"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 me-4"
                          >
                            Organization
                          </label>
                        </div>
                      )}
                    />
                  </div>

                  <div className="flex items-center">
                    <Controller
                      name="organization"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <div className="flex">
                          <input
                            {...field}
                            defaultChecked
                            type="radio"
                            id="individual-radio"
                            value="Individual"
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                              errors.organization && "border-red-500"
                            }`}
                          />
                          <label
                            htmlFor="individual-radio"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Individual
                          </label>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <p className="text-red-500 text-sm mb-6">
                  {!selectedOrganization &&
                    errors.organization &&
                    errors.organization.message}
                </p>

                {!watch("organization") && selectedOrganization && (
                  <p className="text-red-500 text-sm mt-1">
                    Please select an organization type before proceeding.
                  </p>
                )}
              </>
            ) : null}

            <div className="mb-6">
              <input
                {...register("address")}
                placeholder="Address *"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.address && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.address?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("zipcode")}
                placeholder="Zip Postal Code *"
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                onBlur={zipCodeDetails}
                type="number"
                maxLength={6}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.zipcode && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.zipcode?.message}
              </p>

              {!errors.zipcode?.message && (
                <p className="text-red-500 text-sm mt-1">{notFoundMessage}</p>
              )}
            </div>
            <div className="mb-6">
              <input
                {...register("city")}
                placeholder="City *"
                type="text"
                value={cityName}
                readOnly
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.city && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p
                className={`text-red-500 text-sm mt-1 ${cityName && "hidden"}`}
              >
                {errors.city?.message}
              </p>
            </div>
            <div className="mb-6">
              <input
                {...register("state")}
                placeholder="State *"
                type="text"
                readOnly
                value={stateName}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.state && stateName == null ? "border-red-500" : ""
                }`}
              />
              <p
                className={`text-red-500 text-sm mt-1 ${stateName && "hidden"}`}
              >
                {errors.state?.message}
              </p>
            </div>

            <div className="flex items-center my-8">
              <label
                htmlFor="countries"
                className="block me-8 text-sm font-medium text-gray-400"
              >
                Country*
              </label>
              <select
                {...register("country", { required: "Country is Required *" })}
                id="countries"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 ${
                  errors.state && !countryFlag ? "border-red-500" : ""
                }`}
                value={countryFlag ? "United States" : ""}
                disabled
              >
                <option value="">Select Country</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
            <p
              className={`text-red-500 text-sm mt-1 ${countryFlag && "hidden"}`}
            >
              {errors.country?.message}
            </p>

            <div className="flex justify-between mt-10 space-x-3">
              <div>
                <button
                  type="submit"
                  className="text-white px-6 py-2 rounded-sm btn-style-1"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default AddAddressDetails;
