import React, { useRef, useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { async } from "q";
import { Triangle } from "react-loader-spinner"; // Import Triangle loader
import axios from "axios";
import { TQ_URL } from "../../utility/baseUrl";
import {
  getRequestWithTokenWithoutData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";

const schema = yup.object().shape({
  individualName: yup.string().required("Individual's name is required"),
  address: yup.string().required("Address is required"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),

  fundraiserGoal: yup
    .number()
    .required("Fundraiser goal is required")
    .positive("Fundraiser goal should be positive or non-zero")
    .typeError("Fundraiser goal Amount should not be Empty"),
  targetDate: yup.date().required("Target Date is required"),
  uploadedDocs: yup.array().of(yup.string()),
});

const EditFundraiser = ({ onClose, fundraiserData, onUpdateRow }) => {
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});

  const fileInputRef = useRef();
  // console.log("Fundraiser  data.. :", fundraiserData);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchUpdatedData = async (fundraiserId) => {
    try {
      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/getFundraiserProfileDetailsToEdit`,
        {
          fundraiserId: fundraiserId,
        }
      );

      onUpdateRow(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        // Display a generic error message
        console.error("Error fetching updated data:", error.message);
        toast.error("Error fetching updated data.");
      }
    }
  };
  useEffect(() => {
    if (fundraiserData) {
      setValue("individualName", fundraiserData.fundraiserName);
      setValue("address", fundraiserData.address);

      setValue("title", fundraiserData.title);
      setValue("description", fundraiserData.desc);
      setValue("fundraiserGoal", fundraiserData.goalAmt);
      setValue("targetDate", fundraiserData.targetDate);
      setUploadedDocs(fundraiserData.docNamesList || []);
    }
  }, [fundraiserData]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        console.log(data.partnerId);
      })
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);

    const file = e.target.files[0];

    if (file) {
      const fileSizeInMegabytes = file.size / (1024 * 1024); // Convert bytes to megabytes
      const maxSize = 20; // Maximum allowed size in megabytes

      if (fileSizeInMegabytes > maxSize) {
        toast.error(
          `${file.name} File size exceeds the maximum limit of ${maxSize} MB`
        );
        e.target.value = null;
        return;
      }
    }

    const newFiles = Array.from(files);
    setUploadedDocs((prevDocs) => [
      ...prevDocs,
      ...files.map((file) => file.name),
      // ...newFiles,
    ]);

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveDocument = (index) => {
    setUploadedDocs((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  const handleUpdateFundraiser = (data, event) => {
    setLoading(true);
    console.log("Form data:", data);

    const partnerId = config.partnerId;
    console.log(partnerId);

    if (uploadedFiles.length == 0 && uploadedDocs.length == 0) {
      toast.error("Please select document to upload..");
      return;
    }

    event.preventDefault();
    //  setLoading(true);

    const formData = new FormData();

    console.log(fundraiserData.fundraiserId);

    const selectedDate = data.targetDate.toISOString().split("T")[0];
    console.log("target date:" + selectedDate);
    formData.append("fundraiserId", fundraiserData.fundraiserId);
    formData.append("fundraiserName", data.individualName);
    formData.append("address", data.address);
    formData.append("desc", data.description);
    formData.append("title", data.title);
    formData.append("currencyType", "USD");
    formData.append("goalAmt", parseFloat(data.fundraiserGoal));

    formData.append("targetDate", selectedDate);
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append(`file`, file);
      });
    }

    uploadedDocs.forEach((doc) => {
      formData.append(`doc`, doc);
    });

    console.log(formData);
    const token = localStorage.getItem("accessToken");

    // Send the file and additional data using Axios
    axios
      .put(`${TQ_URL}/editFundraiserDetails`, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        setLoading(false);
        causeUpdated();
        fetchUpdatedData(response.data.data.fundraiserId);

        setTimeout(() => {
          onClose();
        }, 2000);
      })
      .catch((error) => {
        toast.error("Something Went wrong");
        console.error("Error uploading file:", error);
      });
  };

  const causeUpdated = () => toast.success("Fundraiser Updated Successfully!");

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-scroll z-50 md:px-4 px-2">
      <div className="fade-in-bottom rounded-xl md:w-1/3 ">
        <div className="bg-white rounded-t-xl max-w-2xl w-full flex justify-between items-center">
          <h2 className="text-xl font-bold mb-4 text-start pt-4 px-10">
            Edit Fundraiser
          </h2>
          <button onClick={onClose} className="px-2 cursor-pointer">
            <svg
              className="w-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                  fill="#0F0F0F"
                ></path>
              </g>
            </svg>
          </button>
        </div>

        <div className="p-6 bg-blue-50 rounded-b-xl">
          {loading ? (
            <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
              <Triangle
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          ) : (
            <form onSubmit={handleSubmit(handleUpdateFundraiser)}>
              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Individual's Name
                </label>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    {...register("individualName")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.individualName && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.individualName?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Address
                </label>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    {...register("address")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.address && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.address?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Title
                </label>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    {...register("title")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.title && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.title?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Description
                </label>
                <div className="md:w-2/3">
                  <textarea
                    {...register("description")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.description && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Fundraiser Goal
                </label>
                <div className="md:w-2/3">
                  {/* <input
                    type="number"
                    {...register("fundraiserGoal")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.fundraiserGoal && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.fundraiserGoal?.message}
                  </p> */}

                  <Controller
                    name="fundraiserGoal"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="number" // Use type text instead of number
                          className={`border p-2 w-full shadow-md rounded-lg ${
                            errors.fundraiserGoal && "border-red-500"
                          }`}
                          {...register("fundraiserGoal")}
                          {...field}
                        />
                      </>
                    )}
                  />

                  <p className="text-red-500 text-sm mt-1">
                    {errors.fundraiserGoal?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-semibold">
                  Target Date
                </label>
                <div className="md:w-2/3">
                  <input
                    type="date"
                    {...register("targetDate")}
                    className={`border p-2 w-full shadow-md rounded-lg ${
                      errors.targetDate && "border-red-500"
                    }`}
                  />
                  <p className="text-red-500 text-sm mt-1">
                    {errors.targetDate?.message}
                  </p>
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-bold">
                  Uploaded Documents
                </label>
                <div className="md:w-2/3 mb-4 flex flex-wrap">
                  {uploadedDocs.map((doc, index) => (
                    <div key={index} className="mr-2 mb-2 relative">
                      <span className="border p-2 bg-white shadow-md rounded-lg px-6">
                        {doc}
                      </span>
                      <button
                        type="button"
                        onClick={() => handleRemoveDocument(index)}
                        className="absolute top-0 right-2  text-black rounded-full"
                      >
                        &#x2715;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="md:flex mb-4">
                <label className="block md:w-1/3 text-left pr-4 font-bold">
                  Upload Documents
                </label>
                <div
                  className={`md:w-2/3 mb-4 border p-6 bg-white shadow-md rounded cursor-pointer ${
                    uploadedDocs.length ? "hover-shadow" : ""
                  }`}
                  onClick={() => fileInputRef.current.click()}
                >
                  <input
                    type="file"
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                    className="hidden"
                  />
                  {!uploadedDocs.length && <p>Click to upload documents</p>}
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="bg-gray-300 !font-bold text-gray-700 py-2 mr-2 px-10 btn-style-3 !rounded-md"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded btn-style-1"
                >
                  Update Fundraiser
                </button>
              </div>
            </form>
          )}
          ;
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default EditFundraiser;
