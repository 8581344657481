import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { Link, useNavigate } from "react-router-dom";
import {
  getRequestWithTokenWithData,
  getRequestWithTokenWithoutData,
  getRequestWithTokenWithDataWithAuth,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import { async } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email("Invalid email format")
  //     .required("Email is Required *"),
  //contactNumber: yup.string().required("Phone is Required *"),
  // Add more validations for other fields if needed
  userType: yup.string(), // Add validation for userType if needed
  userName: yup.string(), // Add validation for userName if needed

  // postalCode: yup
  //   .number()
  //   // .required("Zip Postal Code is Required * ")
  //   .positive("Zip Postal should be positive or non-zero")
  //   .typeError("Zip Postal should not be Empty"),
});

const CreateProfile = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    //postalCode: "",
  });

  const [stateName, setStateName] = useState(null);
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [flag, setFlag] = useState(false);
  const [cityName, setCityName] = useState(null);

  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const [config, setConfig] = useState({});
  // console.log("USERS DATA>>>>>", userData);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getUserProfile();
      isMounted.current = false;
    }
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getUserProfile`
      );
      console.log(response);
      //   setUserInfo(response.userOrgRegistrationDetailsDTO);
      setUserData(response);
      //  console.log(response.userOrgRegistrationDetailsDTO);

      setLoading(false);
    } catch (error) {
      console.error("Error in fetching the userData:", error);
    }
  };

  const onSubmitHandler = async (data) => {
    console.log("Form data to update");
    console.log(data);

    // Create the request object using filteredData
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );

    try {
      // Update userData with filteredData
      setUserData((prevUserData) => ({ ...prevUserData, ...filteredData }));

      // Now use the updated userData
      const updatedUserData = { ...userData, ...filteredData };

      // Create the request object using both filteredData and updatedUserData
      let request = {
        userName: updatedUserData.userName,
        address: updatedUserData.address,
        city: updatedUserData.city,
        state: updatedUserData.state,
        contactNumber: updatedUserData.contactNumber,
        country: updatedUserData.country,
        postalCode: updatedUserData.postalCode,
        userType: userType,
        // filteredData: filteredData, // Add the filteredData to the request
      };

      console.log(request);

      const response = await getRequestWithTokenWithDataWithAuth(
        `${TQ_URL}/updateProfile`,
        request
      );
      console.log(response);

      if (response.message) {
        console.log("Success:", response.message);
        // toast.success(response.message);
        successFormSubmit();
      } else if (response.error) {
        console.error("Error:", response.error);
        toast.error(response.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        console.error("Error in updating profile:", error.message);
        toast.error("Error updating profile data.");
      }
    }
  };

  const successFormSubmit = () =>
    toast.success("Profile Updated Successfully!");

  const [showPassword, setShowPassword] = useState(false);

  const handleVerify = () => {
    localStorage.setItem("redirectFrom", "profile");

    navigate("/verifyuser");
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [verified, setVerified] = useState(false);
  const handleCheckboxChange = () => {
    setVerified(!verified);
    setValue("verified", !verified); // Update the form value for 'verified'
  };

  const userDataString = localStorage.getItem("userData");
  const userJson = JSON.parse(userDataString);
  const userJsonId = userJson.userId;
  const userType = userJson.userType;
  const getAddBankAccount = async () => {
    try {
      const response = await getRequestWithTokenWithData(
        `${TQ_URL}/setup-payment`,
        {
          clientId: userJsonId,
        }
      );
      if (response) {
        //const sessionData = await response.json();
        window.location.href = response.url;
      } else {
        console.error("Error initiating payment setup");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const zipCodeDetails = async (event) => {
    const zipcode = event.target.value;
    console.log(zipcode + "this is my zip");

    if (!zipcode) {
      setNotFoundMessage("Zip Code is required");
      setStateName("");
      setValue("postalCode", zipcode);
      setFlag(false);
      return;
    }
    if (zipcode.length === 5) {
      try {
        const response = await fetch(`${TQ_URL}/getAddressDetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            zipcode: zipcode,
          }),
        });

        if (response.status == 200) {
          const res = await response.json();
          if (res.Status == "Succsess") {
            setUserData((prev) => ({ ...prev, state: res.State.state }));
            setNotFoundMessage("");
            // setCityName(res.State.city);
            setUserData((prev) => ({ ...prev, city: res.State.city }));
            setUserData((prev) => ({ ...prev, zip: zipcode }));
            setFlag(true);
          } else {
            setNotFoundMessage("Incorrect Zip Code");
            setStateName("");
            setFlag(false);
          }
        }
      } catch (error) {
        console.error("Error making post request:", error);
      }
    } else {
      setStateName("");
      setFlag(false);
      setNotFoundMessage("Incorrect Zip Code");
    }
  };

  return (
    <>
      <Header />
      <div className="flex flex-col md:flex-row min-h-screen ">
        {/* Left Section */}
        <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-profile-bg">
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="absolute inset-0 flex flex-col items-start justify-end md:px-20 px-5">
            <h3 className="text-white txt-style-1 mb-5">
              Empower Your Impact:
            </h3>
            <h3 className="text-white txt-style-2 mb-10">
              Updating Lives, <br />
              One Profile at a Time
            </h3>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:flex-1 flex items-center justify-start  p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full  h-auto fade-in-right">
          <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
            {/* Logo */}
            <div className="text-center md:text-left mb-10 sm:mb-10 flex md:justify-between justify-center">
              <Link to="/">
                <img
                  src="assets/images/main-logo.svg"
                  alt="Logo"
                  className="h-10 mx-auto sm:mx-0 hidden md:block"
                />
              </Link>

              <button
                className="btn-style-1 text-white px-6 py-2 rounded-md"
                onClick={getAddBankAccount}
              >
                Add Bank Details
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)} className="">
              <div className="mb-6 md:flex justify-between">
                <label className="w-3/12">Email:</label>
                <input
                  {...register("email")}
                  type="text"
                  defaultValue={userData.email}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.email && "border-red-500"
                  }`}
                  disabled
                />
                {/*     <span className="text-red-500 text-sm mt-1">{errors.email?.message}</span>*/}
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">Phone:</label>
                <input
                  {...register("contactNumber")}
                  type="text"
                  maxLength={13}
                  defaultValue={userData.contactNumber}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.contactNumber && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.contactNumber?.message}
                </span>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">User Type:</label>
                <input
                  {...register("userType")}
                  type="text"
                  defaultValue={userData.userType}
                  readOnly
                  style={{ cursor: "not-allowed" }}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userType && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.userType?.message}
                </span>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">User Name:</label>
                <input
                  {...register("userName")}
                  type="text"
                  defaultValue={userData.userName}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userName && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.userName?.message}
                </span>
              </div>

              <div className="mb-6 md:flex">
                <label className="w-3/12">Address:</label>
                <input
                  {...register("address")}
                  type="text"
                  defaultValue={userData.address}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.userName && "border-red-500"
                  }`}
                />
                <span className="text-red-500 text-sm mt-1">
                  {errors.address?.message}
                </span>
              </div>
              <div className="flex space-x-6">
                <div className="mb-6 md:flex w-1/2">
                  <label className="w-9/12">City:</label>
                  <input
                    {...register("city")}
                    type="text"
                    readOnly
                    defaultValue={userData.city}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.city?.message}
                  </span>
                </div>
                <div className="mb-6 md:flex w-1/2 items-center ">
                  <label className="w-9/12 flex md:justify-center">
                    State:
                  </label>
                  <input
                    {...register("state")}
                    type="text"
                    readOnly
                    // value={stateName}
                    defaultValue={userData.state}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.state?.message}
                  </span>
                </div>
              </div>
              <div className="flex space-x-6">
                <div className="mb-6 md:flex w-1/2">
                  <label className="w-9/12 ">Zip:</label>
                  <input
                    {...register("postalCode")}
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    onBlur={zipCodeDetails}
                    maxLength={6}
                    defaultValue={userData.postalCode}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.zip && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.zip?.message}
                  </span>

                  {!errors.zip?.message && (
                    <span className="text-red-500 text-sm mt-1">
                      {notFoundMessage}
                    </span>
                  )}
                </div>
                <div className="mb-6 md:flex w-1/2 items-center justify-center">
                  <label className="w-9/12 flex md:justify-center">
                    Country:
                  </label>
                  <input
                    {...register("country")}
                    type="text"
                    readOnly
                    defaultValue={userData.country}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                      errors.userName && "border-red-500"
                    }`}
                  />
                  <span className="text-red-500 text-sm mt-1">
                    {errors.country?.message}
                  </span>
                </div>
              </div>
              {/*   <div className="mb-6 flex items-center space-x-4">
                            <input
                              type="checkbox"
                                 {...register('verified')}
                                    checked={verified}
                                    onChange={handleCheckboxChange}
                                    className="form-checkbox h-5 w-5 text-blue-600 ml-2"
                                />
                                <label className="w-3/12">Verified</label>
                                    </div> */}
              {/* {userData.isVerified ? (
                <div className="mb-6 flex items-center space-x-4">
                  <input
                    type="checkbox"
                    {...register("verified")}
                    checked={userData.isVerified}
                    // onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600 ml-2"
                  />
                  <label className="w-3/12">Verified</label>
                </div>
              ) : (
                <div className="mb-6">
                  <p>
                    <a
                      to="#"
                      onClick={handleVerify}
                      className="btn-style-4 px-8 py-2 "
                    >
                      Verify Yourself
                    </a>
                  </p>
                </div>
              )} */}

              {/* ************** */}

              <div className="flex w-full justify-center items-center">
                <button
                  type="submit"
                  className="md:w-1/4 w-1/2 text-white p-2 rounded-md btn-style-1 "
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer theme="colored" />
      </div>
      <Footer />
    </>
  );
};

export default CreateProfile;
