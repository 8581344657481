import React, { useEffect, useState, useMemo } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../Pagination";
import { TQ_URL } from "../../utility/baseUrl";
import fetchConfig from "../../config"; // Import the fetchConfig function
import { Triangle } from "react-loader-spinner"; // Import Triangle loader

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

let PageSize = 12;

const BrowseFundraiser = () => {
  const [counter, setCounter] = useState(1);
  const [heading, setHeading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [fundraisers, setFundraisers] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [partnerId, setPartnerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  let initialKeyword = state?.keyword || ""; // Set to an initial value or an empty string
  const [keyword, setKeyword] = useState(initialKeyword);

  useEffect(() => {
    setKeyword(initialKeyword);
    setCurrentPage(1);
  }, [initialKeyword]);

  const {
    formState: { errors, isValid },
    setValue,
  } = useForm();
  const [showRelationDropdown, setShowRelationDropdown] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [counter]);

  useEffect(() => {
    // Fetch config and set partnerId
    const fetchPartnerId = async () => {
      const config = await fetchConfig();
      setPartnerId(config.partnerId);
    };

    fetchPartnerId();
  }, []);

  useEffect(() => {
    if (partnerId) {
      if (keyword !== undefined && keyword !== null && keyword !== "") {
        fetchSearchedFundraiserData(currentPage);
      } else {
        fetchFundraiserData(currentPage);
      }
    }
  }, [keyword, currentPage, partnerId]);

  const fundraiserList = useMemo(() => {
    return fundraisers;
  }, [PageSize, fundraisers]);

  const fetchFundraiserData = async (page) => {
    try {
      setHeading(1);
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `${TQ_URL}/browseFundraiserList?page=${page}&partnerId=${partnerId}`
      );
      if (response.data.message) {
        setError(response.data.message);
      } else {
        setFundraisers(response.data.datalist);
        setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      setError("Error fetching fundraisers: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchedFundraiserData = async (page) => {
    try {
      setLoading(true);
      setHeading(2);
      setError(null);
      const response = await axios.post(
        `${TQ_URL}/searchFundraiserList?page=${page}&size=${PageSize}`,
        {
          keywordText: keyword,
          partnerId: partnerId,
        }
      );
      if (response.data.message) {
        setError(response.data.message);
      } else {
        setFundraisers(response.data.datalist);
        setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      setError("Error fetching fundraisers: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageError = (event) => {
    event.target.src = "assets/images/fundraiser-1.jpg";
  };

  const handleCreateFundraiserClick = () => {
    const localStorageData = JSON.parse(localStorage.getItem("userData"));
    if (
      localStorageData != null &&
      localStorage.getItem("accessToken") !== null
    ) {
      navigate("/createfundraiser");
    } else {
      navigate("/login");
    }
  };

  const { register: registerRelation } = useForm();

  return (
    <>
      <Header />

      <div className="flex items-center justify-between ">
        <div className="flex-grow text-center">
          {heading == 1 && (
            <h2 className="text-xl md:text-3xl font-bold mb-4 text-center uppercase md:py-10 py-5">
              Browse Fundraisers
            </h2>
          )}

          {heading == 2 && (
            <h2 className="text-xl md:text-3xl font-bold mb-4 text-center uppercase md:py-10 py-5">
              Searched Fundraisers
            </h2>
          )}
        </div>

        <div className="flex justify-end md:mr-[130px]">
          <button
            className="btn-style-1 py-2 text-white px-10 rounded mb-4 "
            onClick={handleCreateFundraiserClick}
          >
            Create
          </button>
        </div>
      </div>

      <div className="flex flex-wrap justify-start create-fundraiser-sec mx-auto 2xl:ps-40 md:ps-20 ps-2 pb-10">
        {loading ? (
          <div className="flex items-center justify-center h-[200px] w-full md:pr-20">
            <Triangle
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              visible={true}
            />
          </div>
        ) : error ? (
          <p className="mx-auto text-center text-lg font-semibold mt-5">
            {error}
          </p>
        ) : fundraiserList.length === 0 ? (
          <p className="mx-auto text-center text-lg font-semibold mt-5">
            No Data Found
          </p>
        ) : (
          fundraiserList.map((data, index) => (
            <div
              key={data.fundraiserId || index}
              className="shadow-md fundraiser-box text-center w-full sm:w-1/3 md:w-1/3 lg:w-1/4 2xl:w-1/5"
            >
              <div className="flex">
                {data.imageList && data.imageList.length > 0 ? (
                  <img
                    src={data.imageList[0]}
                    onError={handleImageError}
                    alt="Data Image"
                    className="w-24 object-cover h-24 my-auto ms-4"
                  />
                ) : (
                  <img
                    src="assets/images/fundraiser-1.jpg"
                    alt="Default Image"
                    className="w-24 object-cover h-24 my-auto ms-4"
                  />
                )}

                <div className="text-center px-10 w-full py-4">
                  <h2 className="text-sm text-left truncate-text">
                    {truncateText(data.fundraiserName, 20)}
                  </h2>
                  <div className="flex justify-between mb-2">
                    <h2 className="text-sm text-left overflow-hidden overflow-ellipsis line-clamp-2 ">
                      <b className="truncate-text">
                        {truncateText(data.title, 20)}
                      </b>
                    </h2>
                  </div>

                  <p className="fundraiser-info-text mb-2 flex justify-between">
                    <div>
                      <span className="fundraiser-info-numbers">
                        ${data.amtAchieved} &nbsp;
                      </span>
                      Raised
                    </div>
                    <span className="fundraiser-info-numbers">
                      {data.amountAchivedInPercetage}%
                    </span>
                  </p>

                  <div className="progress-bar-green mb-2">
                    <div
                      className="progress-bar-fill-green rise-in-left"
                      style={{
                        width: `${
                          (counter / 10) * data.amountAchivedInPercetage
                        }%`,
                      }}
                    />
                  </div>
                </div>
              </div>

              <Link to={`/fundraiser/${data.fundraiserId}`}>
                <button className="btn-style-1 py-2 text-white mb-2 px-6 rounded !font-normal text-xs">
                  Donate
                </button>
              </Link>
            </div>
          ))
        )}
      </div>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />

      <ToastContainer theme="colored" />

      <Footer />
    </>
  );
};

export default BrowseFundraiser;
