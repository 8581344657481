import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { TQ_URL } from "../../utility/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is Required *"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must be at most 25 characters")
    .required("Password is Required *"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required *"),
  organizationName: yup.string().required("Organization Name is Required *"),
  username: yup
    .string()
    .min(5, "Username must be at least 5 characters")
    .max(50, "Username must be at most 50 characters")
    .required("Username is Required *"),
  contactnumber: yup
    .string()
    .required("Contact Number is Required *")
    .test("only-digit", "Contact must be a number", (value) =>
      /^[\d+\-]+$/.test(value)
    )
    .test("min-length", "Contact number should be at least 8 digits", (value) =>
      /^[\d+-]{8,}$/.test(value)
    ),

  termsAndConditions: yup
    .boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});

// const TQ_URL = "https://prabalta-ecd23a1754ce.herokuapp.com"

const NgoCreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  // console.log("process.env >>", `${process.env.TQ_URL}`)
  const [loading, setLoading] = useState(false); // New state for loading
  const [selectedOrganization, setSelectedOrganization] = useState(false); // State to track selected organization
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data, event) => {
    setLoading(true); // Set loading to true when making the API call

    console.log(data);
    event.preventDefault();
    const response = await fetch(`${TQ_URL}/registerPartner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        organizationName:data.organizationName,
        userName: data.username,
        email: data.email,
        name: data.username,
        contactNumber: data.contactnumber,
        password: data.confirmPassword,
      }),
    });

    const res = await response.json();

    console.log("BODY >>", res);

    if (response.status === 200) {
      userCreated(data.email);
      reset();
    } else if (
      response.status === 400 &&
      res.message &&
      res.message.length > 0
    ) {
      let errorMsg = "";
      res.message.forEach((msg) => {
        errorMsg = errorMsg + "  " + msg;
      });

      console.log(errorMsg);

      toast.error(errorMsg + "\n", { position: "top-right" });
    } else {
      userNotCreated();
    }
  };

  const handleSocialIconClick = (dynamicUrl) => {
    console.log("function called for social icon");

    const organizationType = watch("organization");

    console.log(organizationType);

    if (!organizationType) {
      setSelectedOrganization(true);
      return; // Stop further execution if organization is not selected
    } else {
      localStorage.setItem("userType", organizationType);

      window.location.href = dynamicUrl;
    }

    //  setSelectedOrganization(organizationType);
  };

  const userCreated = (email) => {
    toast.success("User Created Successfully!", { position: "top-right" });

    setTimeout(() => {
      // navigate("/addaddressdetails");
      navigate(`/add-ngo-addressdetails?email=${encodeURIComponent(email)}`);
    }, 1000);
  };
  const userNotCreated = () => {
    toast.error("Oops ! User Not Created ", { position: "top-right" });
  };

  console.log("Errors:", errors);

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {loading && (
        <div className="fixed top-0 left-0 right-0 h-1 loader-bg-color  z-50"></div>
      )}
      {/* Left Section */}
      <div className="md:flex-1 bg-cover bg-center relative fade-in-left create-ac-bg ">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col items-start justify-start md:px-20 px-5 mt-28">
          <h3 className="text-white txt-style-1 mb-5">
            Work For charity? <br />
            Sign in for a Charity Account?
          </h3>
          <h3 className="text-white txt-style-2 mb-10">Create Account</h3>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:flex-1 flex items-center justify-start p-10 sm:p-10 md:ms-10 max-w-screen-lg mx-auto w-full h-full fade-in-right">
        <div className="max-w-full w-full sm:max-w-lg 2xl:max-w-2xl ">
          {/* Logo */}
          <h1 className="txt-style-3 text-left">
            Thousands Are Raising Funds Online On Prabalta You Can Too!
          </h1>

          {/* Email and Password Fields */}
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            

              
          <div className="mb-6">
              <input
                {...register("organizationName")}
                placeholder="Enter Organization Name*"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.organizationName?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("username")}
                placeholder="Enter Your Name*"
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.username?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("email")}
                placeholder="Enter Email*"
                type="email"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.email?.message}
              </p>
            </div>

            <div className="mb-6">
              <input
                {...register("contactnumber")}
                placeholder="Contact Number *"
                type="text"
                maxLength={13}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.email && "border-red-500"
                }`}
              />
              <p className="text-red-500 text-sm mt-1">
                {errors.contactnumber?.message}
              </p>
            </div>

            <div className="mb-6 relative">
              <input
                {...register("password")}
                placeholder="Password*"
                type={showPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.password && "border-red-500"
                }`}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.password?.message}
              </p>
            </div>
            <div className="mb-6 relative">
              <input
                {...register("confirmPassword")}
                placeholder="Confirm Password*"
                type={showConfirmPassword ? "text" : "password"}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                  errors.confirmPassword && "border-red-500"
                }`}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer h-10"
              >
                {showConfirmPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <path
                      d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                      stroke="#000000"
                      strokeWidth="0.72"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
              <p className="text-red-500 text-sm mt-1">
                {errors.confirmPassword?.message}
              </p>
            </div>

            

            {/* Terms and Conditions Checkbox */}
            <div className="mb-6">
              <input
                {...register("termsAndConditions")}
                type="checkbox"
                className={`mr-2`}
              />
              <label className="text-dark-200 ">
                I have read and accept the{" "}
                <a onClick={() => setShowTermsPopup(true)}>
                  {" "}
                  <u>terms and conditions</u>
                </a>
              </label>
              <p className="text-red-500 text-sm mt-1">
                {errors.termsAndConditions?.message}
              </p>
            </div>

            {/* Terms and Conditions Popup */}
            {showTermsPopup && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-md max-w-md w-full">
                  <h2 className="text-xl font-bold mb-4">
                    Terms and Conditions
                  </h2>
                  
By registering with our non-profit organization, you agree to abide by our mission, values, and policies. You understand that any misuse of resources or violation of ethical standards may result in termination of your registration.{" "}
                  <div className="mt-4">
                    <button
                      onClick={() => {
                        setShowTermsPopup(false);
                        // Proceed to the next step after accepting terms
                      }}
                      className="mt-6 p-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="md:flex w-full justify-between items-center space-y-4 md:space-y-0">
              {/* Sign Up Button */}
              {/* <Link to="/addaddressdetails"> */}
              <button
                type="submit"
                className=" w-full  md:w-1/3 text-white p-2 rounded-md  btn-style-1"
              >
                Sign Up
              </button>

              {/* </Link> */}
              <p className="text-sm text-dark-600 text-center">
                <i> Or Sign Up With </i>
              </p>

              {/* Social Media Buttons */}
              <div className="flex justify-center space-x-4">
                <a
                  href="#"
                  onClick={() =>
                    handleSocialIconClick(
                      "http://localhost:8080/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/redirect"
                    )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 488 512"
                  >
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                  </svg>
                </a>

                <a
                  href="#"
                  onClick={() =>
                    handleSocialIconClick(
                      "http://localhost:8080/oauth2/authorize/facebook?redirect_uri=http://localhost:3000/oauth2/redirect"
                    )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                  </svg>
                </a>

                <a
                  href="#"
                  onClick={() =>
                    handleSocialIconClick(
                      "http://localhost:8080/usproject/oauth2/authorize/microsoft?redirect_uri=http://localhost:3000/oauth2/redirect"
                    )
                  }
                  className="text-white p-2 rounded-full border w-8 h-8 flex items-center justify-center hover:shadow-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
                  </svg>
                </a>
              </div>
            </div>
            {/* <h1 className="txt-style-4 text-left text-xl">
              <i> Disclaimer - </i>
            </h1>
            <p className="para-style-1">
              <i>
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque feugiat lorem ac aliquam imperdiet. Suspendisse
                quis sapien mollis tortor fermentum ornare. Sed a consectetur
                mauris.
              </i>
            </p> */}
          </form>

          <ToastContainer theme="colored" />
        </div>
      </div>
    </div>
  );
};

export default NgoCreateAccount;
