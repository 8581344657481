import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

export const HowWeWorkModal = ({ showModal, onClose }) => {
  if (!showModal) return null;

  return (
    <div className="overlay flex items-center mb-12 justify-center overflow-y-auto z-50 px-4 cust-height">
      <div className="bg-white rounded-t-xl  items-center p-8 how-we-work-modal">
        <button onClick={onClose} className="mt-2 p-2 cursor-pointer">
          <svg
            className="w-8 md:w-10 lg:w-8"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                fill="#0F0F0F"
              ></path>{" "}
            </g>
          </svg>{" "}
        </button>

        <h2 className="md:text-3xl text-xl font-extrabold mb-4 flex items-center justify-center h-full ">
          How We Work ?
        </h2>

        <div className="box-content h-auto rounded-2xl p-4 border-4 text-black flex mb-8 flex-col md:flex-row fade-in-right shadow-md md:w-[50rem]">
          <div className="md:flex md:py-2 container mx-auto">
            <div className="md:w-2/5 mx-4 fade-in-left">
              <img
                src="assets/images/hww1.jpg"
                className="w-full md:w-auto"
                alt="Vector"
              />
            </div>
            <div className="md:w-3/5 flex flex-col items-start justify-center md:text-left mx-4 md:mx-2 fade-in-right">
              <div>
                <h2 className="md:text-2xl text-md font-extrabold md:mb-4 mb-2">
                  If You are Donor?
                </h2>
                <ul className="flex flex-col list-disc pl-6">
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Browse or search fundraiser, Click on desired fundraiser.
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Input transaction details for donation.
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Provide payment information like credit card information.
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Receive an email confirmation for donating successfully.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="box-content h-auto rounded-2xl p-4 border-4  text-black flex  mb-8 flex-col md:flex-row fade-in-right shadow-md md:w-[50rem]">
          <div className="md:flex md:py-2 container mx-auto">
            <div className="md:w-3/5 flex flex-col  items-start justify-center md:text-left mx-4 md:mx-2 fade-in-right">
              <div>
                <h2 className="md:text-2xl text-md font-extrabold md:mb-4 mb-2">
                  Want to create new Fundraiser ?
                </h2>
                <ul className="flex flex-col list-disc pl-6">
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Login to the Our website(Required)
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Choose fundraiser type or relation (if it is raised for
                    someone else)
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Provide the necessary document and personal details.
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Recieve an email confirmation for fundraiser creation
                    successfully.
                  </li>
                </ul>
              </div>
            </div>

            <div className="md:w-2/5 mx-4 fade-in-left">
              <img
                src="assets/images/hww_work.jpg"
                className="w-full md:w-auto"
                alt="Vector"
              />
            </div>
          </div>
        </div>

        <div className="box-content h-auto rounded-2xl p-4 border-4  text-black flex  mb-8 flex-col md:flex-row fade-in-right shadow-md md:w-[50rem]">
          <div className="md:flex md:py-2 container mx-auto">
            <div className="md:w-2/5  mx-4 fade-in-left">
              <img src="assets/images/hww-vector-2.png" className="w-" />
            </div>
            <div className="md:w-3/5 flex flex-col items-start justify-center md:text-left mx-4 md:mx-2 fade-in-right">
              <div>
                <h2 className="md:text-2xl text-md font-extrabold md:mb-4 mb-2">
                  If You are Recurring Donor ?
                </h2>
                <ul className="flex flex-col list-disc pl-6">
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Login to the Our website( Required)
                  </li>
                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Choose freqency and fill the amount and other necessary
                    Information.
                  </li>

                  <li className="md:mb-4 mb-2 md:text-md text-sm md:font-bold">
                    Recieve an email confirmation for subscribe successfully.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWorkModal;
