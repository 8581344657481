import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  getRequestWithTokenWithoutData,
} from "../../utility/apiRequest";
import { TQ_URL } from "../../utility/baseUrl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .positive("Amount must be a positive number")
    .required("Amount is required"),
  frequency: yup.string().required("Frequency is required"),
});

const DonateNow = ({ onComplete }) => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(1);
  const [causeList, setCauseList] = useState([]);
  const [selectedCauseType, setSelectedCauseType] = useState([]);
  const [frequencies, setFrequencies] = useState([]);
  const isMounted = useRef(true);
  const [startIndex, setStartIndex] = useState(0);
  const [visibleCauses, setVisibleCauses] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [errorCauseType, setErrorCauseType] = useState("");
  const [config, setConfig] = useState({});

  const itemsPerPage = 6;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchConfig();
  }, []);

  useEffect(() => {
    if (isMounted.current && config.partnerId) {
      getCauseList();
      getFrequencyList();
      isMounted.current = false;
    }
  }, [config]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 10) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [counter]);

  const fetchConfig = async () => {
    try {
      const response = await fetch("/config.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config.json:", error);
    }
  };

  const getCauseList = async () => {
    const partnerId = config.partnerId;
    if (!partnerId) {
      throw new Error("Partner ID is not defined");
    }
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getCauseList?partnerId=${partnerId}`
      );
      const visibleCausess = response.data.slice(
        startIndex,
        startIndex + itemsPerPage
      );
      setCauseList(response.data);
      setVisibleCauses(visibleCausess);
    } catch (error) {
      handleError(error, "Error fetching cause list data.");
    }
  };

  const getFrequencyList = async () => {
    try {
      const response = await getRequestWithTokenWithoutData(
        `${TQ_URL}/getFrequencyList`
      );
      console.log(response);
      setFrequencies(response.data);
    } catch (error) {
      // console.error("Error in fetching the frequency list:", error);
      if (error.response && error.response.data && error.response.data.error) {
        console.error("Error:", error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        console.error("Error fetching cause list data:", error.message);
        toast.error("Error fetching cause list data.");
      }
    }
  };

  const handleError = (error, defaultMessage) => {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error);
    } else {
      toast.error(defaultMessage);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const uniqueValues = [...new Set(value)];
    setSelectedValues(uniqueValues);
    setErrorCauseType(uniqueValues ? "" : "Please select a Cause type");
    const selectedCauseIds = uniqueValues.map((item) => item.causeId);
    setSelectedCauseType(selectedCauseIds);
  };

  const handlePrevClick = () => {
    setStartIndex(Math.max(0, startIndex - itemsPerPage));
    setVisibleCauses(causeList.slice(startIndex, startIndex + itemsPerPage));
  };

  const handleNextClick = () => {
    setStartIndex(
      Math.min(causeList.length - itemsPerPage, startIndex + itemsPerPage)
    );
    setVisibleCauses(causeList.slice(startIndex, startIndex + itemsPerPage));
  };

  const onSubmitHandler = async (data, event) => {
    if (selectedCauseType.length <= 0) {
      setErrorCauseType("Please select Cause Type");
      return;
    }
    setErrorCauseType("");
    event.preventDefault();
    const request = {
      donatedAmt: data.amount,
      frequency: data.frequency,
      causeType: selectedCauseType,
      freqId: data.frequency,
      autoPay: false,
      currencyCode: "USD",
      status: 1,
    };
    navigate("/stripe-donation", { state: request });
  };

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  const handleDonateNowClick = () => {
    navigate(localStorage.getItem("accessToken") ? "/donate" : "/login");
  };

  return (
    <div>
      <Header />
      <div className="fundraiser-banner-section md:py-10 pt-6">
        <div className="container mx-auto md:flex items-center justify-between">
          <div className="md:w-2/5 ">
            <div>
              <img
                src="/assets/images/donation-bg.jpg"
                className="fundraiser-slider-box rounded-md"
              />
            </div>
          </div>

          <div className="md:w-1/2 pr-6 md:py-20 p-4 ">
            <h2 className="text-2xl font-semibold mb-4 w-2/3">
              Recurring Donation Details
            </h2>
            <h1 className="banner-text-2 leading-none w-full ">
              Sustain Our Cause,
              <span className="yellow-texture-bg "> Recurringly</span>
            </h1>
            <h1 className="banner-text-2 !text-white !text-6xl md:!text-9xl w-full ">
              DonateNow
            </h1>

            <h2 className="text-2xl font-semibold mb-4 w-1/2">{"  "}</h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2">&nbsp;</h2>
            <h2 className="text-2xl font-semibold mb-4 w-1/2 text-blue-800 ">
              &nbsp;
            </h2>
          </div>
        </div>
      </div>

      <div className="md:py-20 md:flex justify-between ">
        <div className="2xl:w-1/4 lg:w-1/3 me-20  md:py-0 w-full ">
          <img
            src="/assets/images/donation-form-img.jpg"
            className="w-full rounded-r-lg"
          />
        </div>

        <div className="md:w-2/3">
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="fundraiser-for-other-form md:py-12 md:px-20 md:me-20 bg-white px-4 py-6 my-4 mx-2"
          >
            <div className="mb-6 md:flex">
              <label>Cause Type*</label>
              <div className="w-full">
                <FormControl
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errorCauseType && "border-red-500"
                  }`}
                >
                  <InputLabel
                    className="text-black-900 font-semibold text-sm"
                    id="demo-multiple-checkbox-label"
                  >
                    Select Causes
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Causes" />}
                    renderValue={(selected) =>
                      selectedValues.map((x) => x.causeName).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {causeList.map((variant) => (
                      <MenuItem
                        key={variant.causeId}
                        value={variant}
                        style={{
                          padding: "10px",
                          fontSize: "16px",
                          margin: "5px",
                        }}
                      >
                        <Checkbox
                          checked={selectedValues.includes(variant)}
                          style={{
                            fontSize: "16px",
                            margin: "10px",
                          }}
                        />
                        <ListItemText primary={variant.causeName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorCauseType && (
                  <p style={{ color: "red" }}>{errorCauseType}</p>
                )}
              </div>
            </div>

            <div className="mb-6 md:flex">
              <label>Amount*</label>
              <div className="w-full">
                <Controller
                  control={control}
                  name="amount"
                  render={({ field }) => (
                    <input
                      {...field}
                      placeholder="Enter Amount*"
                      type="number"
                      min="0"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      onKeyPress={(e) => {
                        if (e.key === "-" || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        if (e.target.value < 0) {
                          e.target.value = "";
                        }
                      }}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                        errors.amount ? "border-red-500" : ""
                      }`}
                    />
                  )}
                />
                <p className="text-red-500 text-sm mt-1">
                  {errors.amount?.message}
                </p>
              </div>
            </div>

            <div className="mb-6 md:flex">
              <label>Select Frequency*</label>
              <div className="block w-full md:flex md:space-x-6">
                {frequencies.map((frequency) => (
                  <div key={frequency.freqId}>
                    <label>
                      <Controller
                        name="frequency"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              type="radio"
                              value={frequency.freqId}
                              id={frequency.freqId}
                              className="me-2"
                            />
                            <label htmlFor={frequency.freqId}>
                              {frequency.frequencyName}
                            </label>
                          </>
                        )}
                      />
                    </label>
                  </div>
                ))}
                <p className="text-red-500 text-sm">
                  {errors.frequency?.message}
                </p>
              </div>
            </div>

            <div className="mb-6 md:flex justify-between">
              <div></div>

              <div className="md:w-4/6">
                <div className="flex w-full md:justify-between justify-center items-center">
                  <button
                    type="submit"
                    className="px-6 md:w-1/3 text-white p-2 rounded-sm btn-style-1 md:mt-12 mt-6 !font-semibold"
                  >
                    Donate Now
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className=" p-8 flex flex-col md:flex-row items-center justify-center md:py-20 py-6 bg-gray-100">
        <div className="2xl:w-1/2 md:w-1/2 md:pr-8 relative">
          <img
            src="assets/images/newCroppedImg.png"
            alt="Image"
            className="object-cover mb-6 md:mb-0 below-live-feed-img  w-full h-auto"
          />
          <div className="counter-overlay  bottom-20 left-16 right-0 bg-blue-500 text-white text-center p-2">
            <p className="text-4xl font-bold ">
              {counter}+{" "}
              <p className="text-xs font-semibold blue-box-text">
                Happy Clients{" "}
              </p>
            </p>
          </div>
        </div>

        <div className="2xl:w-1/3 md:w-1/2 flex flex-col items-start md:text-left ">
          <h2 className="text-lg md:text-2xl font-semibold mb-4">
            Contribute Monthly
            <br />
            To Preserve Innumerable Lives
          </h2>
          <p className="text-black font-medium md:mb-10 mb-5 text-sm md:text-md">
            "Giving Every Month" is a monthly subscription program specifically
            crafted to support patients in obtaining critical and life-saving
            medical care promptly.
          </p>

          <button
            className="btn-style-1 py-2 text-white px-6 rounded mb-4"
            onClick={handleDonateNowClick}
          >
            Give Every Month
          </button>
          <div className="counter-with-text">
            <p className="md:text-2xl font-bold mb-2 italic">
              <span className="orange-txt ">100%</span> Transparency |
              Charitable Tax Benefits
            </p>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
      <Footer />
    </div>
  );
};

export default DonateNow;
