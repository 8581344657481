import React,{useEffect} from 'react'
import Header from '../Home/Header'
import Footer from '../Home/Footer'
import { Link } from 'react-router-dom'


const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    


    return (
        <>
            <Header />
            {/* Banner Section */}
            <div className='font-family-poppins'>
                <div className="about-page-banner-section text-white 2xl:px-20 md:px-10 px-4 flex justify-between w-full flex-col md:flex-row">
                    <div>
                        <div className='flex items-center fade-in-left h-full'>
                            <h1 className="banner-text-2 md:w-7/12 leading-[2.7rem] md:leading-[5rem]">Committed To Change & <span className='yellow-texture-bg '>To Each </span>Other</h1>
                        </div>
                    </div>
                </div>

                <div className="py-10 md:py-20 md:flex justify-between">
                    <div className='md:w-1/2 2xl:w-6/12 md:me-10 m-4 md:m-0'>
                        <img src="assets/images/about-us-img.jpg" className="w-full" />
                    </div>

                    <div className="md:w-2/5 flex flex-col items-start md:text-left mx-4 md:mx-0">
                        <h2 className="text-3xl font-extrabold mb-4 ">About Us</h2>
                        <p className="text-black font-medium md:mb-10 md:leading-8 leading-6">
                            <b>
                            At Prabalta, we're all about empowering change and lifting communities. As a non-profit, we link donors with fundraisers, simplifying the process of giving back and creating real-world impact.
                            </b><br /><br />
                            We aim to bridge the gap between those who have the means to help and those who are in need of support, ensuring that every donation makes a meaningful difference. Whether you're making a one-time donation, setting up a recurring donation to support your favorite cause regularly, or encountering any issues along the way, our dedicated team is here to support you every step of the journey.
                            </p>
                                       </div>
                    <div className='w-1/12 2xl:w-3/12 hidden md:block'>
                    </div>
                </div>
                {/* <div>
                    <div className='container mx-auto text-center md:py-5 px-4 md:px-0' >
                        <h2 className="text-2xl font-bold mb-4 ">Our Policy Priorities</h2>
                        <p className="text-black font-medium mb-10 md:leading-10 leading-7">
                            We advocate for a variety of policies at the state and federal level that exemplify our mission to support survivors, remember those who have died and prevent future pandemics. The policies range from financial support to COVID-19 survivors and scholarships for children whose parents died from COVID, to expanding health care support for Long Haulers and memorializing those who lost their lives to the virus.</p>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default AboutUs